<template>
    <b-card no-body>
        <b-card-header class="d-flex justify-content-between align-items-start">
            <div class="d-flex flex-column">
                <b-card-title>
                    <slot name="title" />
                </b-card-title>

                <b-card-sub-title class="mt-50">
                    <span class="text-body font-weight-bold">
                        {{
                            getFormattedDateRange(
                                periodData.start,
                                periodData.end
                            )
                        }}
                    </span>
                </b-card-sub-title>

                <b-card-sub-title class="mt-50">
                    <span>{{ $t('ui.totalInPeriod') + ': ' }}</span>

                    <span class="text-body font-weight-bold">
                        {{ getFormattedThousands(countData) }}
                    </span>
                </b-card-sub-title>
            </div>

            <!-- datepicker -->
            <div class="d-flex align-items-center">
                <feather-icon icon="CalendarIcon" size="16" class="mr-50" />

                <flat-pickr
                    v-model="datePicker"
                    :config="datePickerConfig"
                    class="form-control flat-picker bg-transparent border-0 shadow-none date-style-custom"
                    placeholder="YYYY-MM-DD"
                />
            </div>
            <!-- datepicker -->
        </b-card-header>

        <b-card-body class="position-relative">
            <!-- Spinner -->
            <div
                v-if="isLoading"
                :style="`min-height: ${height}px;`"
                class="position-relative"
            >
                <div class="centered">
                    <b-spinner variant="primary" />
                </div>
            </div>

            <!-- Chart -->
            <chartjs-component-bar-chart
                v-else
                :height="height"
                :data="chartCounters"
                :options="chartBarOptions"
                :plugins="[ChartDataLabels]"
            />
        </b-card-body>
    </b-card>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */

import {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BSpinner,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import {
    isStatisticsScaleValid,
    isStatisticsTypeValid,
    getDatePickerConfig,
} from '@/utils/dynamicStatistics';
import { watch } from '@vue/composition-api';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    getFormattedDateRange,
    getFormattedThousands,
} from '@/utils/formatter';
import ChartjsComponentBarChart from '@/views/components/chartjs/ChartjsComponentBarChart.vue';
import {
    getChartColorByIndex,
    getChartBarOptions,
} from '@/views/components/chartjs/chartOptions';
import useDynamicStatistics from './useDynamicStatistics';

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BSpinner,
        flatPickr,
        ChartjsComponentBarChart,
    },

    props: {
        scale: {
            type: String,
            required: true,
            validator: isStatisticsScaleValid,
        },

        type: {
            type: String,
            required: true,
            validator: isStatisticsTypeValid,
        },

        valueKeys: {
            type: Array,
            default: () => ['values'],
        },

        deepValues: {
            type: Boolean,
            default: false,
        },

        showLabels: {
            type: Boolean,
            default: true,
        },

        showTooltips: {
            type: Boolean,
            default: false,
        },

        showLegend: {
            type: Boolean,
            default: false,
        },

        hiddenKeys: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            height: 400,
            chartBarOptions: getChartBarOptions({
                showLabels: this.showLabels,
                showTooltips: this.showTooltips,
                showLegend: this.showLegend,
            }),
            ChartDataLabels,

            datePickerConfig: getDatePickerConfig({ scale: this.scale }),
        };
    },

    computed: {
        datasets() {
            return this.valueKeys.map((valueKey, index) => {
                return {
                    data: this.chartData[valueKey],
                    backgroundColor: getChartColorByIndex(index),
                    borderColor: 'transparent',
                    label: this.getLocalizedName(valueKey),
                    hidden: this.hiddenKeys.includes(valueKey),
                };
            });
        },

        reducedDatasets() {
            const values =
                this.chartData.values.reduce((acc, value) => {
                    Object.keys(value).forEach((key) => {
                        if (!acc[key]) {
                            acc[key] = [];
                        }

                        acc[key].push(value[key] ?? 0);
                    });

                    return acc;
                }, {}) || {};

            return Object.keys(values).map((valueKey, index) => {
                const hidden =
                    this.hiddenKeys.includes(valueKey) ||
                    !values[valueKey].reduce((acc, value) => {
                        // eslint-disable-next-line no-param-reassign
                        acc += value;

                        return acc;
                    }, 0);

                return {
                    data: values[valueKey],
                    backgroundColor: getChartColorByIndex(index),
                    borderColor: 'transparent',
                    label: this.getLocalizedName(valueKey),
                    hidden,
                };
            });
        },

        chartCounters() {
            return {
                labels: this.chartData.keys,
                datasets: this.deepValues
                    ? this.reducedDatasets
                    : this.datasets,
            };
        },
    },

    methods: {
        getLocalizedName(valueKey) {
            const path = `chart.${valueKey}`;
            if (this.$te(path)) {
                return this.$t(path);
            }

            return valueKey;
        },
    },

    setup(props) {
        const { scale, type, valueKeys } = props;
        const {
            chartData,
            periodData,
            countData,
            isLoading,
            datePicker,
            fetchDynamicStatistics,
        } = useDynamicStatistics({ scale, type, valueKeys });

        fetchDynamicStatistics();

        watch(datePicker, () => {
            fetchDynamicStatistics();
        });

        return {
            // Reactive data
            chartData,
            periodData,
            countData,
            isLoading,
            datePicker,

            // Methods
            fetchDynamicStatistics,

            // Formatter
            getFormattedDateRange,
            getFormattedThousands,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.date-style-custom {
    text-align: right !important;
    max-width: 90px !important;
    padding: 0 !important;
    cursor: pointer !important;
}
</style>
