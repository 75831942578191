import store from '@/store';
import i18n from '@/libs/i18n/index';
import useHandleError from '@/utils/useHandleError';
import { reactive, ref } from '@vue/composition-api';
import {
    isStatisticsScaleValid,
    isStatisticsTypeValid,
    getStatisticsEndpointByType,
} from '@/utils/dynamicStatistics';
import { getDateForDatePicker, getTimeZoneString } from '@/utils/formatter';

export default function useDynamicStatistics({ scale, type }) {
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);
    const chartData = ref({
        keys: [],
        values: [],
    });
    const periodData = ref({ start: null, end: null });
    const countData = ref(null);
    const isLoading = ref(true);
    const datePicker = ref(getDateForDatePicker(scale));
    const timezone = ref(getTimeZoneString());

    const fetchDynamicStatistics = () => {
        const statisticsEndpoint = getStatisticsEndpointByType(type);

        isLoading.value = true;

        if (
            !isStatisticsScaleValid(scale) ||
            !isStatisticsTypeValid(type) ||
            !statisticsEndpoint
        ) {
            const message = 'Validation error';

            return Promise.reject(new Error(message)).catch(showErrorMessage);
        }

        return store
            .dispatch('app-dynamic-statistics/fetchDynamicStatistics', {
                locale: i18nReactive.locale,
                scale,
                statisticsEndpoint,
                date: datePicker.value,
                timezone: timezone.value,
            })
            .then((responce) => {
                const { chart, period, count } = responce.data;

                chartData.value = chart;
                periodData.value = period;
                countData.value = count;
            })
            .catch(showErrorMessage)
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        // Reactive data
        chartData,
        periodData,
        countData,
        isLoading,
        datePicker,

        // Methods
        fetchDynamicStatistics,
    };
}
