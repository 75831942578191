/* eslint-disable import/no-extraneous-dependencies */

import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
import { endpoints } from '@/utils/api-endpoints';
import { getMaxDate } from '@/utils/formatter';

const {
    newDevicesDynamicStatistics,
    activeDevicesDynamicStatistics,
    trialsDynamicStatistics,
    subscriptionsDynamicStatistics,
} = endpoints;

export const STATISTICS_SCALES = ['daily', 'weekly', 'monthly', 'yearly'];
export const STATISTICS_ENDPOINTS = {
    newDevices: newDevicesDynamicStatistics,
    activeDevices: activeDevicesDynamicStatistics,
    trials: trialsDynamicStatistics,
    subscriptions: subscriptionsDynamicStatistics,
};
export const DATE_FORMATS = {
    daily: 'Y-m-d',
    weekly: 'Y-m-d',
    monthly: 'Y-m',
    yearly: 'Y',
};
export const DATEPICKER_PLUGINS = {
    daily: [],
    weekly: [],
    monthly: [new MonthSelectPlugin({ dateFormat: DATE_FORMATS.monthly })],
    yearly: [new MonthSelectPlugin({ dateFormat: DATE_FORMATS.yearly })],
};

export const isStatisticsScaleValid = (scale) =>
    STATISTICS_SCALES.includes(scale);

export const isStatisticsTypeValid = (type) =>
    Object.keys(STATISTICS_ENDPOINTS).includes(type);

export const getStatisticsEndpointByType = (type) =>
    STATISTICS_ENDPOINTS[type] || null;

export const getDateFormatByScale = (scale) =>
    DATE_FORMATS[scale] || DATE_FORMATS.daily;

export const getDatePickerPluginsByScale = (scale) =>
    DATEPICKER_PLUGINS[scale] || [];

export const getDatePickerConfig = ({ scale }) => {
    return {
        maxDate: getMaxDate(),
        altInput: true,
        altFormat: 'j M Y',
        dateFormat: getDateFormatByScale(scale),
        plugins: getDatePickerPluginsByScale(scale),
    };
};
