import { $themeColors } from '@themeConfig';
import { getFormattedThousands } from '@/utils/formatter';

// colors
export const chartColors = {
    primaryColorShade: '#836AF9',
    yellowColor: '#ffe800',
    successColorShade: '#28dac6',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
    redDeepColor: '#b21e35',
    orangeLightColor: '#ffd166',
    irisColor: '#80ffdb',
    limeColor: '#7bf1a8',
    greyMiddleColor: '#353535',
    violetColor: '#e500a4',
};

export const getChartColorByIndex = (index) => {
    const COLORS = [
        $themeColors.primary,
        $themeColors.success,
        $themeColors.warning,
        $themeColors.danger,
        $themeColors.info,
        chartColors.warningColorShade,
        chartColors.lineChartDanger,
        chartColors.blueLightColor,
        chartColors.limeColor,
        chartColors.redDeepColor,
        chartColors.orangeLightColor,
        chartColors.irisColor,
        chartColors.violetColor,
        chartColors.greyMiddleColor,
    ];

    return COLORS[index] || chartColors.blueLightColor;
};

export const getChartBarOptions = ({
    showLabels = false,
    showLegend = false,
    showTooltips = false,
}) => {
    return {
        elements: {
            rectangle: {
                borderWidth: 2,
                borderSkipped: 'bottom',
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
            display: showLegend,
            position: 'bottom',
        },
        tooltips: {
            enabled: showTooltips,
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
        },
        hover: {
            mode: null,
        },
        scales: {
            xAxes: [
                {
                    display: true,
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color,
                    },
                    scaleLabel: {
                        display: false,
                    },
                    ticks: {
                        fontColor: chartColors.labelColor,
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                    gridLines: {
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color,
                    },
                    ticks: {
                        min: 0,
                        fontColor: chartColors.labelColor,
                    },
                    beforeFit(scale) {
                        if (!showLabels) return;

                        // Find max value in your dataset
                        let maxValue = 0;

                        if (
                            scale.chart.config &&
                            scale.chart.config.data &&
                            scale.chart.config.data.datasets
                        ) {
                            scale.chart.config.data.datasets.forEach(
                                (dataset) => {
                                    if (dataset && dataset.data) {
                                        dataset.data.forEach((value) => {
                                            if (value > maxValue) {
                                                maxValue = value;
                                            }
                                        });
                                    }
                                }
                            );
                        }

                        // After, set max option !!!
                        const spaceLeft = 1 - maxValue / scale.max;

                        if (spaceLeft <= 0.05)
                            // eslint-disable-next-line no-param-reassign
                            scale.max = maxValue + maxValue * 0.1;
                    },
                },
            ],
        },
        plugins: {
            datalabels: {
                display: showLabels,
                anchor: 'end',
                align: 'top',
                color: $themeColors.dark,
                font: {
                    weight: 'bold',
                },
                formatter: (value) => {
                    if (value === 0) return '';

                    return getFormattedThousands(value);
                },
            },
        },
    };
};

export const chartDoughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 500,
    cutoutPercentage: 95,
    legend: { display: false },
    tooltips: {
        callbacks: {
            label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || '';
                const value = data.datasets[0].data[tooltipItem.index];
                const output = ` ${label} : ${value}`;

                return output;
            },
        },
    },
    // Updated default tooltip UI
    shadowOffsetX: 1,
    shadowOffsetY: 1,
    shadowBlur: 8,
    shadowColor: chartColors.tooltipShadow,
    backgroundColor: $themeColors.light,
    titleFontColor: $themeColors.dark,
    bodyFontColor: $themeColors.dark,
};
